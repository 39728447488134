/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import {
    object, string,
} from 'prop-types';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import mbpLogger from 'mbp-logger';

import Media from 'react-media';
import { getBrandName } from '../../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import findURL from '../../../../../gql/queries/findURL';
import findCategoryPageByURL from '../../../../../gql/queries/findCategoryPageByURL';
import { trackEvent } from '../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import useExperimentServiceAttributes from '../../../../../helpers/experimentService/useExperimentServiceAttributes';

const useStyles = makeStyles((theme) => ({
    root: {
        boxSizing: 'border-box',
        padding: theme.spacing(3, 0, 0, 0),
        display: 'block',
        height: '290px',
        width: '100%',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    mobileRoot: {
        width: '100%',
        margin: '15px 0px',
        border: '1px solid #eee',
        boxShadow: '0 8px 4px -4px rgba(59,33,81,0.21)',
        textDecoration: 'none',
    },
    posRel: {
        position: 'relative',
        marginBottom: '5px',
    },
    imageAspectRatio: {
        paddingBottom: '44.5%', // HEIGHT / WIDTH IN THIS CASE
    },
    mobileImg: {
        width: '100%',
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
    },
    headline: {
        margin: `0 auto ${theme.spacing(3)}px auto`,
        maxWidth: '500px',
        textAlign: 'center',
        fontSize: '34px',
        color: theme.palette.common.black, // TODO: Font color should come from CMS
        '& p,h1,h2,h3,h4,h5,h6': {
            marginBottom: 0, // fix for spacing for bumping Gift Finder
            fontSize: '34px',
            '& strong': {
                display: 'block',
            },
        },
    },
    mobileHeadline: {
        margin: '0 auto',
        width: '100%',
        textAlign: 'center',
        color: theme.palette.common.black,
        '& p,h1,h2,h3,h4,h5,h6': {
            fontSize: '16px',
            fontWeight: 'normal',
            '& strong': {
                display: 'block',
            },
        },
        '& > *': {
            margin: '0 auto 3px auto',
        },
    },
    ctaLink: {
        textDecoration: 'none',
    },
    cta: {
        boxSizing: 'border-box',
        display: 'block',
        margin: '0 auto',
        fontSize: '18px',
        fontWeight: '800',
        borderRadius: '2px',
    },
    mobileCta: {
        display: 'block',
        margin: '0 auto 15px auto',
        height: '40px',
    },
}));

const HeroImage = ({
    data,
    ssrDeviceType = '',
}) => {
    const classes = useStyles();
    const domain = useSelector(getBrandName);
    const dispatch = useDispatch();
    const title = data?.hero_link?.title || '';
    const href = data?.hero_link?.href || '';
    const hero_image = data?.hero_image || {};
    const mobile_hero_image = data?.mobile_hero_image || {};
    const markdown = data?.markdown || '';
    const hero_text_font_color = data?.hero_text_font_color || '';
    const hero_text_font_type = data?.hero_text_font_type || '';
    const tracking_event_action = data?.tracking_event_action || 'Hero - [Click]';
    const tracking_event_category = data?.tracking_event_category || 'Homepage';
    const tracking_event_label = data?.tracking_event_label || '';
    const { targeting, context, isGraphqlTargetingEnabled } = useExperimentServiceAttributes({ queryName: 'findCategoryPageByUrl' });

    // Track recently view products click event
    const trackClickEvent = () => {
        dispatch(
            trackEvent({
                eventCategory: tracking_event_category,
                eventAction: tracking_event_action,
                eventLabel: tracking_event_label,
            }));
    };
    // Apollo prefetching from mouseOver TODO mobile
    const FIND_URL_QUERY = findURL(domain, href);
    const FIND_CATEGORY_PAGE_QUERY = findCategoryPageByURL(isGraphqlTargetingEnabled, domain, href);

    const [loadURL, { errorURL }] = useLazyQuery(FIND_URL_QUERY);
    const [loadCategory, { errorCategory }] = useLazyQuery(FIND_CATEGORY_PAGE_QUERY);

    if (errorURL || errorCategory) { mbpLogger.logError({ appName: process.env.npm_package_name, message: ` errorURL: ${errorURL} || errorCategory ${errorCategory}` }); }
    const buttonStyleOverrides = {};
    buttonStyleOverrides.color = data?.hero_button_text_color?.color || '';
    buttonStyleOverrides.background = data?.hero_button_background_color?.color || '';
    return (
        <Link
            to={href}
            title={title}
            className={classes.ctaLink}
            onClick={() => trackClickEvent()}
        >
            <Media
                query="(min-width: 767px)"
                defaultMatches={ssrDeviceType === 'desktop'}
                render={() => (
                    <div
                        style={{
                            backgroundImage: `url(${hero_image?.url}?format=webp&optimze={medium})`,
                        }}
                        className={classes.root}
                        onMouseOver={() => {
                            loadURL();
                            loadCategory({
                                variables: {
                                    ...(isGraphqlTargetingEnabled ? { targeting } : {}),
                                },
                                context,
                            });
                        }}
                        onFocus={() => {
                            loadURL();
                            loadCategory({
                                variables: {
                                    ...(isGraphqlTargetingEnabled ? { targeting } : {}),
                                },
                                context,
                            });
                        }}
                    >
                        <div
                            className={classes.headline}
                            style={{
                                fontFamily: hero_text_font_type || null,
                                color: hero_text_font_color || null,
                            }}
                        >
                            <ReactMarkdown
                                source={markdown}
                                skipHtml
                            />
                        </div>
                        {title && (
                            <Button
                                style={buttonStyleOverrides}
                                variant="contained"
                                color="secondary"
                                className={classes.cta}
                                disableRipple
                                data-test="hp-shop-now-button"
                                id="btn-shop-now"
                                name="shopNowButton"
                            >
                                {title}
                            </Button>
                        )}
                    </div>
                )}
            />

            <Media
                query="(max-width: 767px)"
                defaultMatches={ssrDeviceType === 'mobile'}
                render={() => (
                    <div className={classes.mobileRoot}>
                        <div className={classes.posRel}>
                            <div className={classes.imageAspectRatio} />
                            <img className={classes.mobileImg} src={`${mobile_hero_image?.url}?auto=webp&optimze={medium}`} alt={title} />
                        </div>
                        <div
                            className={classes.mobileHeadline}
                            style={{
                                fontFamily: hero_text_font_type || null,
                            }}
                        >
                            <ReactMarkdown
                                source={markdown}
                                skipHtml
                            />
                        </div>
                        {title && (
                            <Button
                                style={buttonStyleOverrides}
                                variant="contained"
                                color="secondary"
                                className={classes.mobileCta}
                                disableRipple
                                data-test="hp-shop-now-button"
                                id="btn-shop-now"
                                name="shopNowButton"
                            >
                                {title}
                            </Button>
                        )}
                    </div>
                )}
            />
        </Link>
    );
};

HeroImage.defaultProps = {
    ssrDeviceType: '',
};

HeroImage.propTypes = {
    data: object.isRequired,
    ssrDeviceType: string,
};

export default HeroImage;
